import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import Table from "react-bootstrap/Table";

const AllDetailOfPerson = ({ showAllDetails, setShowAllDetails, data, travel_data = false }) => {
    console.log("hello", data);

    // New data to be added if travel_data is false
    const newData = {
        visitor_name: "Visitor Name",
        mobile_number: "Mobile Number",
        company_name: "Company Name",
        designation: "Designation",
        visiting_as: "Visiting As",
        gst_number: "GST Number",
        email: "Email",
        approved_status: "Approved Status",
        street_address: "Street Address",
        city: "City",
        state: "State",
        postal_code: "Postal Code",
        no_of_stores: "No. Of Stores",
        sqft_area: "Sqft Area",
        no_of_staff: "No Of Staff",
        company_category: "Company Category",
        // photo: "Photo",
        // id_proof_front: "ID Proof Front",
        // id_proof_back: "ID Proof Back",
        barcode: "Barcode"
    };

    // Existing headerData with common fields
    const headerData = {
        email: 'Email',
        travelMode: "Travel Mode",
        departureCity: "Departure City",
        departureDate: "Departure Date",
        departure_time_from_source: "Departure Time",
        arrivalTimeGoa: "Arrival Time",
        flightTrainName: "Flight/Train Name",
        flightTrainNumber: "Flight/Train Number",
        
        
        travelReturnMode: "Travel Return Mode",
        returnCity: "Return City",
        returnFlightDate: "Return Flight Date",
        returnFlightDepartureTime: "Return Flight Departure Time",
        returnFlightName: "Return Flight Name",
        returnFlightNumber: "Return Flight Number",
        
        
        hotelCheckInDate: "Hotel Check-In Date",
        hotelCheckOutDate: "Hotel Check-Out Date",
        
      
      
      
      
    };

    // Check if travel_data is false and merge new data
    const combinedData = !travel_data ? { ...headerData, ...newData } : headerData;

    // Define an ordered array of keys to control rendering order
    const orderedKeys = [
        'visitor_name', 'mobile_number', 'company_name', 'designation', 'visiting_as', 'gst_number', 'email', 
        'approved_status', 'street_address', 'city', 'state', 'postal_code', 'no_of_stores', 'sqft_area', 
        'no_of_staff', 'company_category', 'photo', 'id_proof_front', 'id_proof_back', 'barcode',
        'travelMode', 'departureCity', 'departureDate','departure_time_from_source','arrivalTimeGoa', 'flightTrainName', 'flightTrainNumber', 'travelReturnMode',
        'returnCity','returnFlightDate','returnFlightDepartureTime', 'returnFlightName', 'returnFlightNumber', 'hotelCheckInDate', 
        'hotelCheckOutDate'
    ];

    return (
        <div>
            <Modal
                show={showAllDetails}
                onHide={() => setShowAllDetails(false)}
                animation={false}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Detail of the <strong>{data?.visitor_name}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Scrollbars style={{ height: '60vh' }}>
                        {data ? (
                            <ul className="list-group">
                                {orderedKeys
                                    .filter((key) => combinedData.hasOwnProperty(key) && data[key] !== undefined)
                                    .map((key) => (
                                        <li key={key} className="list-group-item d-flex justify-content-between align-items-center my-2 shadow">
                                            <span className="font-weight-bold">{combinedData[key]}:</span>
                                            <span>{data[key]}</span>
                                        </li>
                                    ))}
                            </ul>
                        ) : (
                            <>No data...</>
                        )}

                        {/* Conditionally rendering the table */}
                        {data?.categories && data?.categories.length > 0 && (
                            <Table className="table table-striped my-3 p-3 data-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.categories.map((category, index) => (
                                        <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{category.name}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    disabled
                                                    className="form-control"
                                                    min="0"
                                                    value={category.percentage}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                        {data?.other_category && data?.other_category.length > 0 && (
                            <Table className="table table-striped my-3 p-3 data-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.other_category.map((category, index) => (
                                        <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{category.key}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    disabled
                                                    className="form-control"
                                                    min="0"
                                                    value={category.value}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </Scrollbars>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AllDetailOfPerson;
