import React, { useEffect, useState } from "react";
import axios from "axios";
import MainLogo from "../../../src/assets/image/main-logo.png";
import { useLocation } from "react-router-dom";
import Footer from "../superAdmin/footer/Footer";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import moment from 'moment';

import Select from "react-select";
import { Spinner } from "react-bootstrap";
import TokenUndefined from "../Service/TokenUndefined";
import { message, notification } from "antd";
import FormatChecker from "../Service/FormatChecker";
import DatePickers from "../../common/DatePickers";

// Function to convert a date string to ISO 8601 format
const convertToISO = (dateStr) => {
  // Parse the date string to a Date object
  const date = new Date(dateStr);
  // Convert to ISO 8601 format with time set to 00:00:00
  return date.toISOString().split('T')[0] + 'T00:00:00';
};

// Function to check if a given date is in the specified range
const isDateInRange = (date) => {
  // Define the start and end dates in ISO format
  const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE));
  const endDate = new Date(process.env.REACT_APP_END_DATE);

  // Convert the given date to ISO format and create a Date object
  const givenDate = new Date(convertToISO(date));

  // Perform the date range check
  return givenDate >= startDate && givenDate <= endDate;
};



// const isDateInRange = (date) => {
//   // Define the start and end dates in ISO format
//   const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE_FLIGHT));
//   const endDate = new Date(process.env.REACT_APP_END_DATE_FLIGHT);

//   // Convert the given date to ISO format and create a Date object
//   const givenDate = new Date(convertToISO(date));

//   // Perform the date range check
//   return givenDate >= startDate && givenDate <= endDate;
// };

function getNextDayFormatted(date) {
  return moment(date).add(1, 'days').format('lll');
}


const initialFormData = {
  arrivalTimeGoa: '',
  departureCity: '',
  flightTrainName: '',
  flightTrainNumber: '',
  returnFlightDepartureTime: '',
  returnFlightName: '',
  returnFlightNumber: '',
  travelMode: '',
  returnCity: '',
  travelReturnMode: '',
  nameOfRepresentative: '',
};

// Example usage
const inputDate = "Nov 14, 2024";
const isValid = isDateInRange(inputDate);

console.log(isValid); // Output: false (since Nov 14, 2024 is not in the range)

function VisitorForm({
  formHeader,
  formName,
  editStatus,
  companyType,
  edit_name,
  edit_contact,
  edit_phone,
  edit_email,
  id,
  barcode,
  barcodeId,
  travelDetails,
}) {
  let editName = edit_name === undefined ? "" : edit_name;
  let editContact = edit_contact === undefined ? "" : edit_contact;
  let editPhone = edit_phone === undefined ? "" : edit_phone;
  let editEmail = edit_email === undefined ? "" : edit_email;

  const navigate = useNavigate();

  let currentLocation = useLocation().pathname;

  const designationArray = ["MD", "Director", "Proprietor", "Owner", "Partner"];
  const visitingAsArray = [
    "Wholesaler",
    "Manufacturer",
    "Retailer",
    "Chain Store   ",
    "Agent/Broker",
  ];

  const categoryArray = [
    "Management",
    "Office Staff",
    "Support Staff"
  ]
  const [number, setNumber] = useState(editPhone);
  const [visitorName, setVisitorName] = useState(editContact);
  const [designation, setDesignation] = useState("");
  const [visitingCategory, setVisitingCategory] = useState("");
  const [visitingID, setVisitingID] = useState("");
  const [visiting_as, setVisiting_as] = useState("");
  const [yourCompany, setYourCompany] = useState();
  const [comapnyList, setComapnyList] = useState([]);
  const [invitedByCompany, setInvitedByCompany] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState(editEmail);
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressTwo, setstreetAddressTwo] = useState("");
  const [country, setCountry] = useState("India");
  const [hearAbout, setHearAbout] = useState("");
  const [photo, setPhoto] = useState();
  const [idProofFront, setIDProofFront] = useState();
  const [idProofBack, setIDProofBack] = useState();
  const [invitedCompanyName, setInvitedCompanyName] = useState(editName);
  const [visitingDay, setVisitingDay] = useState("");
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exhibitorName, setExhibitorName] = useState("");
  const [photoStatus, setPhotoStatus] = useState(false);
  const [idFrontStatus, setIdFrontStatus] = useState(false);
  const [idBackStatus, setIdBackStatus] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [gst_number, setGst_number] = useState("");
  const [showError, setShowError] = useState("");
  const [reloadShow, setReloadShow] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [selected_category, setSelected_category] = useState(null)
  const [travelModerarrivalArray, setTravelModerarrivalArray] = useState(["Train", "Flight", "Self"])
  const [formData, setFormData] = useState(initialFormData);
  const [departureDate, setDepartureDate] = useState(null)
  const [error_1st, set_error_1st] = useState(false);
  const [returnFlightDate, setReturnFlightDate] = useState(null)
  const [error_3rd, set_error_3rd] = useState(null);
  const [hotelCheckInDate, setHotelCheckInDate] = useState(null);
  const [hotelCheckOutDate, setHotelCheckOutDate] = useState(null);
  const [alertText, setAlertText] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [formdisability, setFormdisability] = useState(false)
  const dateValidation = `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'companyName') {
      // Capitalize the first letter of each word for companyName
      const uppercaseValue = value.toUpperCase();
      setFormData(prevData => ({ ...prevData, [name]: uppercaseValue }));
    } else if (['representative', 'companyName', 'personName', 'personName2nd'].includes(name)) {
      // Ensure only alphabetic characters for these fields
      const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, '');
      setFormData(prevData => ({ ...prevData, [name]: alphabeticValue }));
    }

    else if (name === 'mobileNumber') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    } else if (name === 'mobileNumber2nd') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    }

    else {
      // For other fields, just update the state as usual
      setFormData(prevData => ({ ...prevData, [name]: value }));
    }
  };



  useEffect(() => {
    if (departureDate) {
      if (!isDateInRange(departureDate)) {
        set_error_1st(true)

        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_1st(false)

      }
    }
  }, [departureDate]);

  useEffect(() => {
    if (returnFlightDate) {
      console.log("hello", returnFlightDate, isDateInRange(returnFlightDate));
      if (!isDateInRange(returnFlightDate)) {
        set_error_3rd(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_3rd(false)

      }
    }
  }, [returnFlightDate]);

  const handleMobileNoChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setNumber(numericValue);

    if (
      numericValue.length === 10 &&
      (formName === "exhibitor_vip" ||
        formName === "exhibitor_owner" ||
        formName === "exhibitor_staff")
    ) {
      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-is-number/${numericValue}/${formName}`
        )
        .then((resData) => {
          setDuplicateError(resData.data.status);
        });
    }
  };

  let formJson = {
    visitor: [
      "mobile",
      "visitor_name",
      "designation",
      "visiting_as",
      // "invited_company",
      "email",
      "street_add_line1",
      "street_add_line2",
      "city",
      "state",
      "zipcode",
      "country",
      "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    exhibitor_owner: [
      "mobile",
      "visitor_name",
      // "designation",
      // "visiting_as",
      "owner_designation",
      // "invited_company",
      "management_category",
      "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    exhibitor_staff: [
      "mobile",
      "visitor_name",
      "designation",
      // "visiting_as",
      // "invited_company",
      "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",

      // "photo",
      // "id_front",
      // "id_back",
      // "visiting_day",
    ],
    exhibitor_modal: ["mobile", "visitor_name", "photo", "id_front", "id_back"],
    exhibitor_family: [
      "mobile",
      "visitor_name",
      "photo",
      "id_front",
      "id_back",
    ],
    exhibitor_vip: [
      "mobile",
      "visitor_name",
      "designation",
      // "visiting_as",
      // "invited_company",
      "email",
      "street_add_line1",
      "street_add_line2",
      "city",
      "gst_number",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      // "photo",
      // "id_front",
      // "id_back",
      // "visiting_day",
      "company_name",
    ],
    vendor_owner: [
      "mobile",
      "visitor_name",
      // "designation",
      // "visiting_as",
      // "invited_company",
      // "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    edit_update: [
      "visitor_name",
      "mobile",
      "invited_company",
      "email",
      "photo",
      "id_front",
      "id_back",
    ],
    edit_form_update: [
      "visitor_name",
      "mobile",
      "invited_company",
      "email",
      "photo",
      "id_front",
      "id_back",
    ],
    vendor_staff: [
      "mobile",
      "visitor_name",
      // "designation",
      // "visiting_as",
      // "invited_company",
      // "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
  };
  function handlePhoto(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setPhotoStatus(dataStatus);
    if (!dataStatus) {
      setPhoto(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }
  const handleName = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setVisitorName(alphabetOnlyValue);
  };
  const handleCity = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCity(alphabetOnlyValue);
  };
  const handleState = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setState(alphabetOnlyValue);
  };
  const handleCountry = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCountry(alphabetOnlyValue);
  };

  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  function checkRegex(input) {
    return regex.test(input);
  }

  function convertToUpperCase(input) {
    return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
  }

  const handleGstNumberChange = (e) => {
    setGst_number(convertToUpperCase(e.target.value));
    const value = e.target.value;
    if (checkRegex(value)) {
      console.log("asdasdasd", e.target.value);
      setShowError("");
    } else {
      setShowError("Provide Valid GSTIN");
    }
  };

  useEffect(() => {
    console.log("hello i am here to see the test daeeed", gst_number.length);

    if (gst_number.length === 15 && checkRegex(gst_number)) {
      // setReloadShow(true);
      // ;

      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-gst-validation/${gst_number}`
        )
        .then((resData) => {
          // setReloadShow(false);
          // console.log(
          //   "hello i am here to see the test daeeed",
          //   resData.data.message,
          //   resData.data.data.lgnm,
          //   resData.data.data.pradr.adr
          // );
          // if (resData.data.message === "GSTIN  found.") {
          console.log("gst find or not", resData.data);
          if (resData.data.flag === false) {
            setShowError(resData.data.message);
            return;
          }
          setShowError("");
          setCompanyName(resData.data.company);
          setStreetAddress(resData.data.address);
          setCity(resData.data.city);
          // }
        });
    }
  }, [gst_number]);

  const handlePostalCode = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setPostalCode(numericValue);
  };
  // useEffect(() => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_END_POINT}/visitor-category`,
  //       {
  //         id: visitingID,
  //       },
  //       {
  //         headers: {
  //           token: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("get-visitor", res.data.data);
  //       setAllCategory(res.data.data);
  //     });
  // }, []);

  function handleIDFront(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setIdFrontStatus(dataStatus);
    if (!dataStatus) {
      setIDProofFront(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }
  function handleIDBack(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setIdBackStatus(dataStatus);
    if (!dataStatus) {
      setIDProofBack(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    emptyField();
  }, [reload]);

  useEffect(() => {
    if (
      formName === "vendor_owner" ||
      formName === "vendor_staff" ||
      companyType === "vendor"
    ) {
      axios
        .get(`${process.env.REACT_APP_API_END_POINT}/get-vendor-list`)
        .then((res) => {
          // console.log("assasdasaadads", res.data.data);
          setComapnyList(res.data.data);
        });
    } else {
      if (formName !== "vendor_staff" && formName !== "vendor_owner") {
        axios
          .get(`${process.env.REACT_APP_API_END_POINT}/get-exhibitor-list`, {
            headers: {
              token: localStorage.getItem("jff_token"),
            },
          })
          .then((res) => {
            // console.log("assasdasaadads", res.data.data);
            setInvitedByCompany(res?.data?.data);
          });
      }
    }
  }, [reload]);

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (formName !== "vendor_staff" && formName !== "vendor_owner") {
      console.log(
        "abcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadadd",
        formName !== "vendor_staff" && formName !== "vendor_owner",
        formName
      );
      TokenUndefined("exhibitor/login");
    }

    if (formName !== "vendor_staff" && formName !== "vendor_owner") {
      axios
        .post(`${process.env.REACT_APP_API_END_POINT}/get-exhibitor-name`, "", {
          headers: {
            token: localStorage.getItem("jff_token"),
          },
        })
        .then((res) => {
          // LoginRedirection(res, "exhibitor/login");
          setExhibitorName(res.data.data);
        })
        .catch((err) => {
          // LoginRedirection("/superadmin/login");

          // if (formName !== "vendor_staff" || formName !== "vendor_owner") {
          if (err.response.status === 401) {
            navigate("/exhibitor/login");
            localStorage.removeItem("jff_token");
            localStorage.removeItem("category");
            localStorage.removeItem("permission");
            localStorage.removeItem("user_id");
            localStorage.removeItem("type_of_user");
          }
          // }
        });
    }

    if (!travelDetails) {
      axios
        .get(`${process.env.REACT_APP_API_END_POINT}/form-status-exhibitor`, {
          headers: {
            token: localStorage.getItem("jff_token"),
          },
        })
        .then((res) => {
          const total_owner_no = res.data.total_owner_no;
          const owner_no = res.data.owner_no;

          if (total_owner_no === '8' && owner_no >= 4) {
            setAlertText(true);
            setAlertMessage('Each participant will receive 4 badges per stall, with the option to get up to 4 additional badges at ₹3,000 each.');
          }

          if (total_owner_no === '16' && owner_no >= 8) {
            setAlertText(true);
            setAlertMessage('Each participant will receive 8 badges per stall, with the option to get up to 8 additional badges at ₹3,000 each.');
          }
        })
        .catch((err) => {
          alert('Error occurred...');
        });
    }
  }, []);






  const validateForm = (formName, formData) => {
    const requiredFields = [
      "travelMode",
      "travelReturnMode"
    ];

    // Add fields based on travelMode
    if (formData.travelMode !== "Self") {
      requiredFields.push("departureCity", "flightTrainName", "flightTrainNumber", "arrivalTimeGoa");
    }

    // Add fields based on travelReturnMode
    if (formData.travelReturnMode !== "Self") {
      requiredFields.push("returnCity", "returnFlightName", "returnFlightNumber", "returnFlightDepartureTime");
    }

    // Validate all required fields
    for (let field of requiredFields) {
      if (
        !formData['travelMode']
        || !formData['travelReturnMode']
      ) {
        continue;
      }

      if (!formData[field]) {
        message.error(`${field.split(/(?=[A-Z])/).join(" ")} is required`);
        return false; // Stop validation if any required field is missing
      }
    }

    return true; // All validations passed
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log("hello i am here", formData);
    setIsLoading(true);

    const dataNeedTOCheck = await axios.get(
      `${process.env.REACT_APP_API_END_POINT}/form-status-exhibitor`,
      {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      }
    );
    if (formName === "exhibitor_owner" && !dataNeedTOCheck.data.ownerstatus) {
      message.error("The limit to add new members has exhausted");
      setIsLoading(false);

      return;
    }
    if (formName === "exhibitor_staff" && !dataNeedTOCheck.data.staffstatus) {
      message.error("The limit to add new members has exhausted");
      setIsLoading(false);

      return;
    }
    if (formName === "exhibitor_vip" && !dataNeedTOCheck.data.vipstatus) {
      message.error("The limit to add new members has exhausted");
      setIsLoading(false);

      return;
    }

    //  formName !== "exhibitor_staff" &&
    // formName !== "exhibitor_vip"
    //  .then((res) => {
    //    console.log(res.data);
    //    if (res.data.staffstatus) {
    //      navigate("/exhibitor/exhibitor-staff-registration");
    //    } else {
    //      message.error("The limit to add new members has exhausted");
    //    }
    //  });

    if (!visitorName) {
      message.error("Name is required");
      setIsLoading(false);
    } else if (!number) {
      message.error("Phone Number is required");
      setIsLoading(false);
    } else if (number.length !== 10) {
      message.error("Please Enter 10 Digit Mobile Number");
      setIsLoading(false);
      return;
    } else if (emailError && (formName !== "exhibitor_vip" || formName !== "exhibitor_staff" || formName !== "exhibitor_owner")) {
      message.error("Invalid email format");
      setIsLoading(false);
      return;
    } else if (!companyName && formName === "exhibitor_vip") {
      message.error("Company Name is required");
      setIsLoading(false);
    } else if (!designation && (formName === "exhibitor_vip" || formName === "exhibitor_staff" || formName === "exhibitor_owner")) {
      message.error("Designation is required");
      setIsLoading(false);
    } else if (!city && formName === "exhibitor_vip") {
      message.error("City is required");
      setIsLoading(false);
    } else if (!country && formName === "exhibitor_vip") {
      message.error("Country is required");
      setIsLoading(false);
    }


    else if (!isDateInRange(hotelCheckInDate) && formName === "exhibitor_owner") {
      notification.error({ message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} return date of second person`, description: '' });

      setIsLoading(false);

    }
    else if (!isDateInRange(hotelCheckOutDate) && formName === "exhibitor_owner") {
      notification.error({ message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} return date of second person`, description: '' });

      setIsLoading(false);


    }

    else if (!validateForm(formName, formData)) {
      setIsLoading(false);

    }
    else if (
      !selected_category &&
      formName === "exhibitor_owner"
    ) {
      message.error("Category is required");
      setIsLoading(false);
    } else {

      if (editStatus) {
        // if (number.length !== 10) {
        //   message.error("Please Enter 10 Digit Mobile Number");
        //   setIsLoading(false);
        //   return;
        // }
        // if (emailError) {
        //   message.error("Invalid email format");
        //   setIsLoading(false);
        //   return;
        // }
        e.preventDefault();
        const sendData = new FormData();
        sendData.append("visitor_name", visitorName);
        sendData.append("email", email);
        sendData.append("company_name", companyName);
        sendData.append("mobile_number", number);
        sendData.append("invited_exhibitor_id", invitedByCompany);
        sendData.append("photo", photo);
        sendData.append("id_proof_front", idProofFront);
        sendData.append("id_proof_back", idProofBack);
        sendData.append("id", id);
        sendData.append("category_visiting", selected_category)
        sendData.append("arrivalTimeGoa", formData.arrivalTimeGoa)
        sendData.append("departureCity", formData.departureCity)
        sendData.append("flightTrainName", formData.flightTrainName)
        sendData.append("flightTrainNumber", formData.flightTrainNumber)
        sendData.append("returnFlightDepartureTime", formData.returnFlightDepartureTime)
        sendData.append("returnFlightName", formData.returnFlightName)
        sendData.append("returnFlightNumber", formData.returnFlightNumber)
        sendData.append("travelMode", formData.travelMode)
        // travelReturnMode
        sendData.append("travelReturnMode", formData.travelReturnMode)

        sendData.append("returnCity", formData.returnCity)
        sendData.append("returnFlightDate", returnFlightDate)
        sendData.append("departureDate", departureDate)

        axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/update-visitor`,
            sendData,
            {
              headers: {
                token: `${localStorage.getItem("jff_token")}`,
                barcode,
              },
            }
          )
          .then((res) => {
            if (res.data.messageData) {
              message.error(res.data.messageData);
              setIsLoading(false);
              emptyField();
            } else {
              window.location.reload(true);
              // setReload(!reload)
              // message.success("Form Submit Successfully");
              window.alert("Form Submit Successfully");
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.error("Axios Error:", error);
          });
      } else {
        // {
        if (!photo && !idProofFront && !idProofBack) {
          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/add-visitor`,
              {
                mobile_number: number,
                visitor_name: visitorName,
                designation: designation,
                visiting_as: visiting_as,
                firm_id: yourCompany,
                invited_exhibitor_id: invitedByCompany,
                email: email,
                company_name: companyName,
                street_address: streetAddress,
                street_address_two: streetAddressTwo,
                city: city,
                gst_number,
                state: state,
                postal_code: postalCode,
                category_visiting: selected_category,

                country: country,
                hear_about_jff: hearAbout,
                category: formName,
                visiting_day: visitingDay,
                visitor_category_id: visitingID,
                barcodeId,
                arrivalTimeGoa: formData.arrivalTimeGoa,
                departureCity: formData.departureCity,
                flightTrainName: formData.flightTrainName,
                flightTrainNumber: formData.flightTrainNumber,
                returnFlightDepartureTime: formData.returnFlightDepartureTime,
                returnFlightName: formData.returnFlightName,
                returnFlightNumber: formData.returnFlightNumber,
                travelMode: formData.travelMode,
                travelReturnMode: formData.travelReturnMode,
                returnCity: formData.returnCity,
                returnFlightDate: returnFlightDate,
                departureDate: departureDate,
                hotelCheckInDate: hotelCheckInDate.toString(),
                hotelCheckOutDate: hotelCheckOutDate.toString(),
                representative: formData.nameOfRepresentative,
              },
              {
                headers: {
                  token: localStorage.getItem("jff_token"),
                },
              }
            )
            .then((res) => {
              // setReload(!reload)
              // window.location.reload(true);
              // message.success("Form Submit Successfully");
              if (res.data.messageData) {
                message.error(res.data.messageData);
                setIsLoading(false);
                emptyField();
              } else {
                window.alert("Form Submit Successfully");
                setIsLoading(false);
                window.location.reload(true);
                if (formName === "exhibitor_owner") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/owner-list");
                } else if (formName === "exhibitor_staff") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/staff-list");
                } else if (formName === "exhibitor_modal") {
                  navigate("/exhibitor/modal-list");
                } else if (formName === "exhibitor_family") {
                  navigate("/exhibitor/family-list");
                } else if (formName === "exhibitor_vip") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/vip-guest-requrest-list");
                }
              }
            })
            .catch((err) => {
              // LoginRedirection("/superadmin/login");
              if (err.response.status === 401) {
                navigate("/superadmin/login");
                localStorage.removeItem("jff_token");
                localStorage.removeItem("category");
                localStorage.removeItem("permission");
                localStorage.removeItem("user_id");
                localStorage.removeItem("type_of_user");
              }
            });
        } else {
          console.log('Hotel details: ', hotelCheckInDate, hotelCheckOutDate, formData.nameOfRepresentative);

          const sendData = new FormData();
          sendData.append("mobile_number", number);
          sendData.append("visitor_name", visitorName);
          sendData.append("designation", designation);
          sendData.append("visiting_as", visiting_as);
          sendData.append("firm_id", yourCompany);
          sendData.append("invited_exhibitor_id", invitedByCompany);
          sendData.append("email", email);
          sendData.append("company_name", companyName);
          sendData.append("street_address", streetAddress);
          sendData.append("street_address_two", streetAddressTwo);
          sendData.append("city", city);
          sendData.append("category_visiting", selected_category)
          sendData.append("state", state);
          sendData.append("postal_code", postalCode);
          sendData.append("country", country);
          sendData.append("hear_about_jff", hearAbout);
          sendData.append("photo", photo);
          sendData.append("id_proof_front", idProofFront);
          sendData.append("id_proof_back", idProofBack);
          sendData.append("category", formName);
          sendData.append("visiting_day", visitingDay);
          sendData.append("visitor_category_id", visitingID);
          sendData.append("barcodeId", barcodeId);
          sendData.append("arrivalTimeGoa", formData.arrivalTimeGoa)
          sendData.append("departureCity", formData.departureCity)
          sendData.append("flightTrainName", formData.flightTrainName)
          sendData.append("flightTrainNumber", formData.flightTrainNumber)
          sendData.append("returnFlightDepartureTime", formData.returnFlightDepartureTime)
          sendData.append("returnFlightName", formData.returnFlightName)
          sendData.append("returnFlightNumber", formData.returnFlightNumber)
          sendData.append("travelMode", formData.travelMode)
          sendData.append("returnCity", formData.returnCity)
          sendData.append("travelReturnMode", formData.travelReturnMode)
          sendData.append("hotelCheckInDate", hotelCheckInDate.toString())
          sendData.append("hotelCheckOutDate", hotelCheckOutDate.toString())
          sendData.append("representative", formData.nameOfRepresentative)

          sendData.append("returnFlightDate", returnFlightDate)
          sendData.append("departureDate", departureDate)
          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/add-visitor`,
              sendData,
              {
                headers: {
                  token: localStorage.getItem("jff_token"),
                },
              }
            )
            .then((res) => {
              // setReload(!reload)
              // window.location.reload(true);
              // message.success("Form Submit Successfully");
              emptyField();
              if (res.data.messageData) {
                alert(res.data.messageData);
                window.location.reload(true);
                setIsLoading(false);
              } else {
                window.alert("Form Submit Successfully");
                setIsLoading(false);
                // window.location.reload(true);
                navigate("/exhibitor/dashboard");
                // console.log("anisdsd", formName);
                if (formName === "exhibitor_owner") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/owner-list");
                } else if (formName === "exhibitor_staff") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/staff-list");
                } else if (formName === "exhibitor_modal") {
                  navigate("/exhibitor/modal-list");
                } else if (formName === "exhibitor_family") {
                  navigate("/exhibitor/family-list");
                } else if (formName === "exhibitor_vip") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/vip-guest-requrest-list");
                }
              }
            })
            .catch((err) => {
              // LoginRedirection("/superadmin/login");
              console.log("hesdsd", err)
              // if (err.response.status === 401) {
              //   navigate("/superadmin/login");
              //   localStorage.removeItem("jff_token");
              //   localStorage.removeItem("category");
              //   localStorage.removeItem("permission");
              //   localStorage.removeItem("user_id");
              //   localStorage.removeItem("type_of_user");
              // }
            });
        }
        // }
      }
    }
  };
  const emptyField = () => {
    setGst_number("");
    setVisitorName("");
    setNumber("");
    setEmail("");
    setCompanyName("");
    setDesignation("");
    setYourCompany("");
    setVisiting_as("");
    setStreetAddress("");
    setstreetAddressTwo("");
    setCity("");
    setState("");
    setPostalCode("");
    // setCountrsy("");
    setHearAbout("");
    setPhoto("");
    setIDProofFront("");
    setIDProofBack("");
    setInvitedByCompany("");
    setVisitingCategory("");
    setVisitingID("");
    setInvitedCompanyName("");
    setVisitingDay("");
  };



  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (number) {
        axios
          .get(
            `${process.env.REACT_APP_API_END_POINT}/check-number-is-present/${number}`)
          .then((resData) => {
            if (resData.data.already_exists) {
              notification.error({
                message: resData.data.message,
                description: 'Enter another Number'
              });
              setFormdisability(true);
            } else {

            }
          })
          .catch((error) => {
            notification.success({
              message: "Number Available",
              description: ''
            });
            setFormdisability(false);
            // handle error if needed
          });
      }
    }, 1500); // 0.5 seconds debounce time

    return () => clearTimeout(debounceTimeout); // Clear the timeout on each number change
  }, [number]);
  return (
    <>
      <>
        {/* <img
          src={MainLogo}
          style={{ maxWidth: "150px" }}
          className="m-2"
          alt=""
        /> */}
        {/* <div
          className="text-center py-2 d-flex justify-content-between"
          // style={{ backgroundColor: "rgb(61, 65, 86)" }}
        > */}
        <div className="text-center">
          <h4 className="form-heading">{formHeader}</h4>
        </div>

        {alertText && <div className="my-5" style={{ color: 'red' }}>
          <h3>Badges Details:</h3> {alertMessage}
        </div>}

        <section>
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="form-body-section">
                <div className="form-body">
                  <div className="row">


                    {formJson[formName].includes("visitor_name") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Name <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="Name"
                          value={visitorName}
                          maxLength={100}
                          required
                          onChange={(e) => {
                            handleName(e);
                          }}
                        />
                      </div>
                    )}

                    {formJson[formName].includes("mobile") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          {formName === "exhibitor_vip" ? (
                            <>
                              Buyer's Mobile Number <font color="#FF0000">*</font>
                            </>
                          ) : (
                            <>
                              Mobile Number <font color="#FF0000">*</font>
                            </>
                          )}
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="Mobile No."
                          value={number}
                          maxLength={10}
                          required
                          onChange={handleMobileNoChange}
                        />
                        {duplicateError && (
                          <p className="text-danger" style={{ fontSize: "10px" }}>
                            This buyer is already registered
                          </p>
                        )}

                      </div>
                    )}

                    {formJson[formName].includes("gst_number") && (
                      <div className="form-group col-lg-6 col-md-6">
                        {reloadShow && (
                          <Spinner
                            style={{
                              height: "20px",
                              width: "20px",
                              position: "absolute",
                              left: "350px",
                              top: " 278px",
                            }}
                          />
                        )}
                        <label className="fw-bold">
                          Gst Number <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          value={gst_number}
                          maxLength={15}
                          placeholder="Gst Number"
                          required
                          onChange={(e) => {
                            handleGstNumberChange(e);
                          }}
                        />
                        {showError && <p className="text-danger">{showError}</p>}
                      </div>
                    )}
                    {formJson[formName].includes("company_name") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Company Name <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="Company Name "
                          required

                          value={companyName}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {formJson[formName].includes("email") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">Email(Optional)</label>
                        <input
                          className="form-control my-2"
                          placeholder="Email"
                          onChange={(e) => {
                            handleEmailChange(e);
                          }}
                        />
                      </div>
                    )}

                    {/* {formJson[formName].includes("designation") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Designation</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {designation ? designation : "Select designation"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {designationArray?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data}
                          onClick={() => setDesignation(data)}
                          className="d-flex justify-content-center"
                        >
                          {data}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}


                    {formJson[formName].includes("management_category") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Category <font color="#FF0000">*</font>
                        </label>
                        <Select
                          className="my-2"
                          required
                          options={categoryArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            selected_category
                              ? { value: selected_category, label: selected_category }
                              : null
                          }
                          onChange={(selectedOption) =>
                            setSelected_category(
                              selectedOption ? selectedOption.value : null
                            )
                          }
                          isSearchable
                          placeholder="Select Category"
                        />
                      </div>
                    )}
                    {formJson[formName].includes("designation") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Designation <font color="#FF0000">*</font>
                        </label>
                        <Select
                          className="my-2"
                          required
                          options={designationArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            designation
                              ? { value: designation, label: designation }
                              : null
                          }
                          onChange={(selectedOption) =>
                            setDesignation(
                              selectedOption ? selectedOption.value : null
                            )
                          }
                          isSearchable
                          placeholder="Select designation"
                        />
                      </div>
                    )}


                    {formJson[formName].includes("owner_designation") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">Designation</label>
                        <input
                          className="form-control my-2"
                          placeholder="Designation"
                          required
                          onChange={(e) => {
                            setDesignation(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {/* {formJson[formName].includes("visiting_as") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>You are visiting show as</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {visiting_as ? visiting_as : "Select visiting as"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {visitingAsArray?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data}
                          onClick={() => setVisiting_as(data)}
                          className="d-flex justify-content-center"
                        >
                          {data}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}

                    {/*  */}

                    {formJson[formName].includes("visiting_as") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          You are visiting show as
                        </label>
                        <Select
                          className="my-2"
                          required
                          options={visitingAsArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            visiting_as
                              ? { value: visiting_as, label: visiting_as }
                              : null
                          }
                          onChange={(selectedOption) =>
                            setVisiting_as(
                              selectedOption ? selectedOption.value : null
                            )
                          }
                          isSearchable
                          placeholder="Select visiting as"
                        />
                      </div>
                    )}
                    {formJson[formName].includes("your_company") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Your Company</label>
                        <input
                          className="form-control my-2"
                          placeholder="Your Company"
                          required
                          value={yourCompany}
                          onChange={(e) => {
                            setYourCompany(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {/* {formJson[formName].includes("invited_company") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Name of the company that invited you</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {invitedCompanyName
                        ? invitedCompanyName
                        : "Select visiting the show as"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {comapnyList?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data.company_name}
                          onClick={() => {
                            setInvitedByCompany(data._id);
                            setInvitedCompanyName(data.company_name);
                          }}
                          className="d-flex justify-content-center"
                        >
                          {data.company_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                    {/* {formJson[formName].includes("invited_company") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold mb-2">
                    Name of the company that invited you
                  </label>
                  <Select
                    options={comapnyList.map((data) => ({
                      value: data.company_name,
                      label: data.company_name,
                    }))}
                    value={
                      invitedCompanyName
                        ? {
                            value: invitedCompanyName,
                            label: invitedCompanyName,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      const selectedCompany = comapnyList.find(
                        (company) =>
                          company.company_name === selectedOption.value
                      );
                      setInvitedByCompany(
                        selectedCompany ? selectedCompany._id : null
                      );
                      setInvitedCompanyName(
                        selectedOption ? selectedOption.value : null
                      );
                    }}
                    isSearchable
                    placeholder="Select the company"
                  />
                </div>
              )} */}
                    {/* {formJson[formName].includes("  ") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold mb-2">
                    Name of the company that invited you
                  </label>
                  <Select
                    options={comapnyList.map((data) => ({
                      value: data.company_name,
                      label: data.company_name,
                      isDisabled: data.company_name === exhibitorName, // Disable if the value matches exhibitorName
                      isSelected: data.company_name === exhibitorName, // Select if the value matches exhibitorName
                    }))}
                    value={
                      exhibitorName
                        ? {
                            value: exhibitorName,
                            label: exhibitorName,
                            isDisabled: true, // Disable the selected option
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      const selectedCompany = comapnyList.find(
                        (company) =>
                          company.company_name === selectedOption.value
                      );
                      setInvitedByCompany(
                        selectedCompany ? selectedCompany._id : null
                      );
                      setInvitedCompanyName(
                        selectedOption ? selectedOption.value : null
                      );
                    }}
                    isSearchable
                    placeholder="Select the company"
                  />
                </div>
              )} */}
                    {formJson[formName].includes("street_add_line2") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">Address</label>
                        <input
                          className="form-control my-2"
                          placeholder="Address"
                          required
                          value={streetAddress}
                          onChange={(e) => {
                            setStreetAddress(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {/* {formJson[formName].includes("street_add_line2") && (
                <div className="form-group col-lg-6 col-md-6 -">
                  <label className="fw-bold">Street Address Line 2</label>
                  <input
                    className="form-control my-2"
                    placeholder="Street Address Line 2"
                    onChange={(e) => {
                      setstreetAddressTwo(e.target.value);
                    }}
                  />
                </div>
              )} */}
                    {formJson[formName].includes("city") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          City <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="City"
                          required
                          value={city}
                          onChange={(e) => {
                            handleCity(e);
                          }}
                          maxLength={25}
                        />
                      </div>
                    )}
                    {formJson[formName].includes("state") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">State</label>
                        <input
                          className="form-control my-2"
                          placeholder="State"
                          required
                          onChange={(e) => {
                            handleState(e);
                          }}
                          maxLength={25}
                        />
                      </div>
                    )}
                    {formJson[formName].includes("zipcode") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">Postal Zip Code</label>
                        <input
                          className="form-control my-2"
                          placeholder="Postal Zip Code"
                          onChange={(e) => {
                            handlePostalCode(e);
                          }}
                          maxLength={6}
                          required
                        />
                      </div>
                    )}
                    {formJson[formName].includes("country") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Country <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          value={country}
                          placeholder="Country"
                          required
                          onChange={(e) => {
                            handleCountry(e);
                          }}
                        />
                      </div>
                    )}
                    {/* {formJson[formName].includes("hduhear") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold">How do you hear about</label>
                  <input
                    className="form-control my-2"
                    placeholder="How do you hear about"
                    onChange={(e) => {
                      setHearAbout(e.target.value);
                    }}
                  />
                </div>
              )} */}
                    {/* 
              {formJson[formName].includes("visitor_category") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Category</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {visitingCategory ? visitingCategory : "Select category"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {allCategory?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data.category_name}
                          onClick={() => {
                            setVisitingCategory(data.category_name);
                            setVisitingID(data._id);
                          }}
                          className="d-flex justify-content-center"
                        >
                          {data.category_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                    {formJson[formName].includes("photo") && (
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label className="fw-bold">
                            Upload Photo{" "}
                            {formName !== "exhibitor_vip" && (
                              <font color="#FF0000">*</font>
                            )}
                            {photoStatus && (
                              <span className="photo-error">
                                Only supported (JPG/JPEG/PNG)
                              </span>
                            )}
                          </label>

                          <input
                            type="file"
                            onChange={handlePhoto}
                            required
                            className="form-control"
                            accept=".jpg, .jpeg, .png"
                          />
                        </div>
                      </div>
                    )}
                    {formJson[formName].includes("id_front") && (
                      <div className="col-lg-6 col-md-6 ">
                        <div className="form-group">
                          <label className="fw-bold">
                            ID Proof(front){" "}
                            {formName !== "exhibitor_vip" && (
                              <font color="#FF0000">*</font>
                            )}
                            {idFrontStatus && (
                              <span className="photo-error">
                                Only supported (JPG/JPEG/PNG)
                              </span>
                            )}
                          </label>
                          <input
                            type="file"
                            onChange={handleIDFront}
                            required
                            className="form-control"
                            accept=".jpg, .jpeg, .png"
                          />
                        </div>
                      </div>
                    )}
                    {formJson[formName].includes("id_back") && (
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label className="fw-bold">
                            ID Proof(back){" "}
                            {formName !== "exhibitor_vip" && (
                              <font color="#FF0000">*</font>
                            )}
                            {idBackStatus && (
                              <span className="photo-error">
                                Only supported (JPG/JPEG/PNG)
                              </span>
                            )}
                          </label>
                          <input
                            type="file"
                            onChange={handleIDBack}
                            required
                            className="form-control"
                            accept=".jpg, .jpeg, .png"
                          />
                        </div>
                      </div>
                    )}
                    {/* {formJson[formName].includes("visiting_day") && (
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label className="fw-bold">Visiting day</label>
                    <div className="d-flex my-2">
                      <div className="form-check me-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="day_one"
                          onClick={(e) => {
                            setVisitingDay(e.target.id);
                            console.log("assadadasdad", e.target.id);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Day One
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="day_two"
                          onClick={(e) => {
                            setVisitingDay(e.target.id);
                            console.log("assadadasdad", e.target.id);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          Day Two
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

                    <div className="row my-5">
                      <h3>Travel Details: </h3>
                      {travelDetails ?
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Travel Mode <font color="#FF0000">*</font>
                        </label>
                        <Select
                          className="my-2"
                          required
                          options={travelModerarrivalArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            formData.travelMode
                              ? { value: formData.travelMode, label: formData.travelMode }
                              : null
                          }

                          onChange={(selectedOption) => { setFormData({ ...formData, travelMode: selectedOption ? selectedOption.value : null }) }}

                          isSearchable
                          placeholder="Choose One"
                        />

                      </div>: <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Travel Mode
                        </label>
                        <Select
                          className="my-2"
                          options={travelModerarrivalArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            formData.travelMode
                              ? { value: formData.travelMode, label: formData.travelMode }
                              : null
                          }
                          onChange={(selectedOption) => { setFormData({ ...formData, travelMode: selectedOption ? selectedOption.value : null }) }}
                          isSearchable
                          placeholder="Choose One"
                        />

                      </div>}

                      {formData.travelMode === "Self" ? null : travelDetails ?
                        <>
                          <div className="form-group col-lg-4 col-md-6">

                            <label className="fw-bold">
                              Departure City (Arriving From Which City) <font color="#FF0000">*</font>
                            </label>
                            <input
                              className="form-control my-2"
                              value={formData.departureCity}
                              required
                              placeholder="Departure City"
                              name="departureCity"
                              onChange={handleChange}
                            />
                          </div>

                        </>: <>
                        <div className="form-group col-lg-4 col-md-6">

                          <label className="fw-bold">
                            Departure City (Arriving From Which City)
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.departureCity}
                            placeholder="Departure City"
                            name="departureCity"
                            onChange={handleChange}
                          />
                        </div>

                      </>}

                      {travelDetails ?
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Date of Arrival <font color="#FF0000">*</font>
                        </label>
                        <DatePickers
                          selectedDate={departureDate} setSelectedDate={setDepartureDate} lable={'Date of Departure'} required={true}

                        />
                        {error_1st && <span style={{ color: 'red' }}>{dateValidation}</span>}
                      </div> : <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Date of Arrival
                        </label>
                        <DatePickers
                          selectedDate={departureDate} setSelectedDate={setDepartureDate} lable={'Date of Departure'} required={false}

                        />
                        {error_1st && <span style={{ color: 'red' }}>{dateValidation}</span>}
                      </div>}

                      {formData.travelMode === "Self" ? null : travelDetails ? <>

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train name <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.flightTrainName}
                            required
                            placeholder="Flight/Train name"
                            name="flightTrainName"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Number <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            placeholder="Flight/Train Number"
                            value={formData.flightTrainNumber}
                            required
                            name="flightTrainNumber"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Arrival time at Delhi <font color="#FF0000">*</font>
                          </label>
                          <input
                            // type="text"
                            type="time"
                            className="form-control my-2"
                            placeholder="Arrival Time"
                            value={formData.arrivalTimeGoa}
                            name="arrivalTimeGoa"
                            required
                            onChange={handleChange}
                          />
                        </div>

                      </> :
                      <>
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Flight/Train name
                        </label>
                        <input
                          className="form-control my-2"
                          value={formData.flightTrainName}
                          placeholder="Flight/Train name"
                          name="flightTrainName"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Flight/Train Number
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="Flight/Train Number"
                          value={formData.flightTrainNumber}
                          name="flightTrainNumber"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Arrival time at Delhi
                        </label>
                        <input
                          // type="text"
                          type="time"
                          className="form-control my-2"
                          placeholder="Arrival Time"
                          value={formData.arrivalTimeGoa}
                          name="arrivalTimeGoa"
                          onChange={handleChange}
                        />
                      </div>

                      </>
                      }


                    </div>

                    <div className="row">

                      {
                        travelDetails ? 
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Travel Mode<font color="#FF0000">*</font>
                        </label>
                        <Select
                          className="my-2"
                          options={travelModerarrivalArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            formData.travelReturnMode
                              ? { value: formData.travelReturnMode, label: formData.travelReturnMode }
                              : null
                          }
                          onChange={(selectedOption) => { setFormData({ ...formData, travelReturnMode: selectedOption ? selectedOption.value : null }) }}

                          required
                          isSearchable
                          placeholder="Choose One"
                        />

                      </div> :
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Travel Mode
                        </label>
                        <Select
                          className="my-2"
                          options={travelModerarrivalArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            formData.travelReturnMode
                              ? { value: formData.travelReturnMode, label: formData.travelReturnMode }
                              : null
                          }
                          onChange={(selectedOption) => { setFormData({ ...formData, travelReturnMode: selectedOption ? selectedOption.value : null }) }}

                          isSearchable
                          placeholder="Choose One"
                        />

                      </div>
                      }




                      {formData.travelReturnMode === "Self" ? null : travelDetails ?
                        <>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Arrival City (Arriving In Which City) <font color="#FF0000">*</font>
                            </label>

                            <input
                              className="form-control my-2"
                              value={formData.returnCity}
                              required

                              placeholder="Arrival City"
                              name="returnCity"
                              onChange={handleChange}
                            />
                          </div>
                        </>:
                        <>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Arrival City (Arriving In Which City)
                            </label>

                            <input
                              className="form-control my-2"
                              value={formData.returnCity}

                              placeholder="Arrival City"
                              name="returnCity"
                              onChange={handleChange}
                            />
                          </div>
                        </>
                        }

                      {travelDetails ?
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Flight/Train Date <font color="#FF0000">*</font>
                        </label>
                        <DatePickers
                          required={true}
                          selectedDate={returnFlightDate} setSelectedDate={setReturnFlightDate} lable={'Date of Departure'}

                        />
                        {error_3rd && <span style={{ color: 'red' }}>{dateValidation}</span>}

                      </div>:
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Flight/Train Date
                        </label>
                        <DatePickers
                          required={false}
                          selectedDate={returnFlightDate} setSelectedDate={setReturnFlightDate} lable={'Date of Departure'}

                        />
                        {error_3rd && <span style={{ color: 'red' }}>{dateValidation}</span>}

                      </div>
                      }

                      {formData.travelReturnMode === "Self" ? null : travelDetails ?
                      <>




                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Name <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.returnFlightName}
                            required

                            placeholder="Flight/Train Name"
                            name="returnFlightName"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Number <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.returnFlightNumber}
                            required

                            placeholder="Flight/Train Number"
                            name="returnFlightNumber"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Time <font color="#FF0000">*</font>
                          </label>
                          <input
                            // type="text"
                            type="time"
                            required

                            className="form-control my-2"
                            placeholder="Returning Time"
                            value={formData.returnFlightDepartureTime}
                            name="returnFlightDepartureTime"
                            onChange={handleChange}
                          />
                        </div>




                      </>:
                      <>




                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Name
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.returnFlightName}

                            placeholder="Flight/Train Name"
                            name="returnFlightName"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Number
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.returnFlightNumber}

                            placeholder="Flight/Train Number"
                            name="returnFlightNumber"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Time
                          </label>
                          <input
                            // type="text"
                            type="time"

                            className="form-control my-2"
                            placeholder="Returning Time"
                            value={formData.returnFlightDepartureTime}
                            name="returnFlightDepartureTime"
                            onChange={handleChange}
                          />
                        </div>




                      </>
                      }

                    </div>

                    <div className="row my-5">
                      <h3>Hotel Details: </h3>
                      {!travelDetails &&
                      <div className="row">
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Check-in Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={hotelCheckInDate} setSelectedDate={setHotelCheckInDate} lable={'Check-in Date'} required={true}
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Check-out Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={hotelCheckOutDate} setSelectedDate={setHotelCheckOutDate} lable={'Check-out Date'} required={true}
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Name of Representative <font color="#FF0000">*</font>
                          </label>

                          <input
                            className="form-control my-2"
                            value={formData.nameOfRepresentative}
                            required
                            placeholder="Name of Representative"
                            name="nameOfRepresentative"
                            onChange={handleChange}
                          />
                        </div> 
                      </div>
                      }
                    </div>

                    <div className="my-5">
  <h3>Hotel Room Details:</h3>
  <ul>
    <li>
      GJC will be providing altogether <strong>6 room nights</strong> on Single/Double occupancy basis in:
      <br />
      <strong>Hyatt Regency Gurugram, NH48, Sector 83, New Delhi, Gurugram, Haryana 122004</strong>
    </li>
    <li>
      PMI participant can utilize these room nights between <strong>15th – 18th February 2025</strong>.
    </li>
    <li>
      Participants who wish to have extra room nights between 15th – 18th February 2025 can book their additional
      room nights as per the following tariff (subject to availability):
    </li>
  </ul>
  <h4>Hotel Room Tariff:</h4>
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>Room Night</th>
        <th>Tariff</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Per Room Night</td>
        <td>Single 11300 + taxes <br /> Double 14500 + taxes</td>
      </tr>
    </tbody>
  </table>
  <p>
    The above discounted rates on Double Occupancy are inclusive of:
    <ul>
      <li>Buffet Breakfast</li>
      <li>Buffet Lunch</li>
      <li>Buffet Dinner</li>
      <li>Airport to & fro transfers by shared car/traveler</li>
    </ul>
  </p>
  <ul>
    <li>
      The room charges for additional rooms should be paid directly to the hotel via online transfers.
    </li>
    <li>
      Add on of Triple Occupant <strong>Rs. 4000/-</strong> plus GST per room/night are payable directly to the hotel.
    </li>
  </ul>
  <p>
    <strong>
      Kindly mention the Names of the representatives who shall be accessing the rooms. GJC will be providing 6
      room nights; the additional room charges will be paid by the PMI Participant as per the above tariff:
    </strong>
  </p>
  <div className="names-section">
    <p>Names of the representatives accessing room nights in Hyatt Regency Gurugram:</p>
  </div>
  <p style={{ color: "red !important;" }}>
    <strong>Kindly Note:</strong> The Check-In on 15th February 2025 will be from <strong>3 PM onwards</strong>, and
    the Check-Out time will be <strong>11 AM</strong> as per the schedule provided by the company.
  </p>
</div>


                    <div className="form-submit mb-5">
                      <Button
                        type="submit"
                        variant="outline-dark"
                        className="mb-5"
                        disabled={isLoading || formdisability}
                      >
                        {isLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </>
      <Footer />
    </>
  );
}

export default VisitorForm;
